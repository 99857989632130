<template>
    <div class="toast-body">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ToastBody'
    }
</script>
