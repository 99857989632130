export default function hasPermission(action, section){

    // Check we have a user (if not then we can't do anything else)
    let user = JSON.parse(localStorage["user"]);
    if(user === undefined || user == null){
        console.log("Permission Check: User info not found");
        return false;
    }

    if(user.permissions === undefined){
        console.log(user.name + " has no permissions set!");
        return false;
    }

    // Admins can do anything
    if((user.permissions.isAdmin !== undefined && user.permissions.isAdmin) || (user.isAdmin !== undefined && user.isAdmin)){
        return true;
    }

    // If the user is not an admin (would of returned true above) and the action requires admin
    if(action == "isAdmin")
    {
        return false;
    }

    let permissions;
    switch(section)
    {
        case "user": permissions = user.permissions.user; break;
        case "customer": permissions = user.permissions.customer; break;
        case "time": permissions = user.permissions.time; break;
        case "project": permissions = user.permissions.project; break;
        case "data": permissions = user.permissions.data; break;
        case "reports": permissions = user.permissions.reports; break;
    }

    //Reports
    if(section == "reports")
    {
        if(action == "view")
        {
            return permissions.summary || permissions.projectSummary || permissions.productivity || permissions.outstandingTime || permissions.detailed;
        }
        else
        {
            return false;
        }
    }

    // You can view financial if you have any permission
    if(section == "project" && action == "viewFinancials")
    {
        return permissions.viewFinancials || permissions.edit || permissions.delete;
    }

    // You can view if you have any permission
    if(action == "view")
    {
        return permissions.view || permissions.edit || permissions.delete || permissions.archive || permissions.add;
    }

    // If you just need any edit perm (this is mainly for page navigation) 
    if(action == "anyEdit")
    {
        return permissions.edit || permissions.delete || permissions.archive || permissions.add;
    }

    switch(action){
        case "add": return permissions.add;
        case "edit": return permissions.edit;
        case "archive": return permissions.archive;
        case "delete": return permissions.delete;
    }

    console.log("Failed to get permission set - user: " + user.email + ", " + section + ", " + action);
    return true;
}